<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div>
    <b-card no-body class="mb-0">
      <!-- Loading -->
      <template v-if="spinner === true">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <div class="text-center m-5">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
          </div>
        </div>
      </template>

      <b-card v-else no-body class="mb-0 card-company-table">
        <!-- Search -->
        <div class="m-2">
          <b-row class="">
            <!-- <b-col md="4">
              <FSearchInput
                urlName="services"
                :fetchDatas="fetchServiceDatas"
              />
            </b-col> -->
            <b-col md="6" class="d-none">
              <FCategoriesCom
                urlName="tags"
                @getNewsCategories="getCategories"
                :isNews="true"
              />
            </b-col>

            <b-col cols="12">
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="primary" v-b-modal.modal-centered>
                  <span class="text-nowrap">Add New Tag</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- Services Table -->
        <Table :items="tags" :fields="fields" @editFunc="editTagData" @delFunc="delTagData" />
      </b-card>
    </b-card>

    <!--Model-->
    <div>
      <b-modal v-model="myModal" id="modal-centered" centered size="lg" header-class="text-uppercase" scrollable
        :title="isEdit ? '' : ''" hide-footer>
        <template #modal-header>
          <!-- Emulate built in modal header close button action -->
          <div class="
              w-100
              d-flex
              justify-content-between
              align-items-center
              text-uppercase
            ">
            <h5 class="mb-0" v-if="!isEdit">Add New Tag</h5>
            <h5 class="mb-0" v-if="isEdit">Edit Tag</h5>
            <b-button variant="outline-danger" @click="close"> X </b-button>
          </div>
        </template>

        <form ref="form" @submit.prevent="handleSubmit">
          <b-form-group>
            <!-- Title -->
            <div class="mb-3">
              <label for="title" class="font-weight-bolder">Name :</label>
              <b-form-input v-model="postForm.name" id="title" placeholder="Enter Tag Title" required>
              </b-form-input>
              <span class="text-danger" v-if="errors.name" >{{ errors.name[0] }}</span>
            </div>

            <div class="mb-3">
              <label for="categories" class="font-weight-bolder">Categories :</label>
              <b-form-select v-model="postForm.category_id" required>
                <b-form-select-option disabled value="">Please Select a category</b-form-select-option>
                <b-form-select-option v-for="c in categories" :key="c.id" :value="c.id">{{ c.name
                }}</b-form-select-option>
              </b-form-select>
              <span class="text-danger" v-if="errors.category_id" >{{ errors.category_id[0] }}</span>
            </div>
          </b-form-group>
          <div class="w-100 text-center" v-if="!isEdit">
            <b-button variant="primary" type="submit" class="col-6">
              <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
              <span v-else>Submit</span>
            </b-button>
          </div>

          <div class="w-100 text-center" v-if="isEdit">
            <b-button variant="primary" type="button" @click="updateTagData(postForm.id)" class="col-6">
              <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
              <span v-else>Update</span>
            </b-button>
          </div>
        </form>
      </b-modal>
    </div>
    <!--Model-->
  </div>
</template>

<script>
import store from "@/store";
import Table from "../components/Table.vue";
import MyPhotoSingleUpload from "../components/MyPhotoSingleUpload.vue";
import FCategoriesCom from "../components/FCategoriesCom.vue";
import { ref, reactive } from "@vue/composition-api";
import {
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BModal,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormCheckbox,
    Table,
    MyPhotoSingleUpload,
    FCategoriesCom
  },
  emits: ["input"],
  setup(props, { emit }) {
    const fields = [
      { key: "id", label: "#" },
      { key: "name", label: "title" },
      { key: "category.name", label: "category name" },
      { key: "actions", label: "actions" },
    ];
    const spinner = ref(false);
    const myModal = ref(false);
    const isEdit = ref(false);
    const tags = ref([]);
    const categories = ref([]);
    const checked = ref(true);
    const postForm = reactive({
      id: "",
      name: "",
      category_id: "",
    });
    const errors = ref({});

    //Datas Fetching
    const fetchTags = () => {
      spinner.value = true;
      store
        .dispatch("app-mypages/fetchDatas", "tags")
        .then((response) => {
          tags.value = response.data.data;
          spinner.value = false;
        });
    };
    fetchTags();

    const getCategories = (fetchedCategories) => {
      categories.value = fetchedCategories;
    };

    //Functions
    const toggle = (c) => {
      c ? (postForm.status = 1) : (postForm.status = 0);
      console.log(postForm.status);
    };

    //Data Posting
    const handleSubmit = () => {
      spinner.value = true;
      store
        .dispatch("app-mypages/postDatas", {
          path: "tags",
          payload: postForm,
        })
        .then((response) => {
          spinner.value = false;
          postForm.name = "";
          postForm.category_id = "";
          fetchTags();
          myModal.value = false;
          isEdit.value = false;
        }).catch((err) => {
          spinner.value = false;
          errors.value = err.response?.data?.errors
        });
    };

    // Data Editing
    const editTagData = (item) => {
      myModal.value = true;
      isEdit.value = true;

      postForm.id = item.id;
      postForm.name = item.name;
      postForm.category_id = item.category_id;
    };
    const updateTagData = (id) => {
      spinner.value = true;
      store
        .dispatch("app-mypages/updateData", {
          path: "tags",
          payload: postForm,
        })
        .then((response) => {
          fetchTags();
          spinner.value = false;
          myModal.value = false;
          isEdit.value = false;
        }).catch((err) => {
          spinner.value = false;
          errors.value = err.response?.data?.errors
        });;
    };

    // Data Deleting
    const delTagData = (id) => {
      spinner.value = true;
      console.log(id);
      store.dispatch("app-mypages/delData", { path: "tags", id }).then((response) => {
        fetchTags();
        spinner.value = false;
      }).catch(() => {
          spinner.value = false;
        });;
    };

    // Close Function
    const close = () => {
      myModal.value = false;
      isEdit.value = false;

      postForm.id = "";
      postForm.name = "";
      postForm.category_id = "";
    };

    return {
      fields,
      spinner,
      // pageNum,
      myModal,
      isEdit,
      tags,
      getCategories,
      categories,
      handleSubmit,
      toggle,
      postForm,
      editTagData,
      updateTagData,
      delTagData,
      close,
      errors
    };
  },
};
</script>