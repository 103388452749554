<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div>
    <b-card no-body class="mb-0">
      <!-- Loading -->
      <template v-if="spinner === true">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <div class="text-center m-5">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
          </div>
        </div>
      </template>

      <b-card v-else no-body class="mb-0 card-company-table">
        <!-- Search -->
        <div class="m-2">
          <b-row>
            <b-col cols="12" md="12">
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="primary" v-b-modal.modal-centered>
                  <span class="text-nowrap">Add New Category</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- Services Table -->
        <Table :items="categories" :fields="fields" @editFunc="editCategoryData" @delFunc="delCategoryData" />
      </b-card>
    </b-card>

    <!--Model-->
    <div>
      <b-modal v-model="myModal" id="modal-centered" centered size="lg" header-class="text-uppercase" scrollable
        :title="isEdit ? '' : ''" hide-footer>
        <template #modal-header>
          <!-- Emulate built in modal header close button action -->
          <div class="
              w-100
              d-flex
              justify-content-between
              align-items-center
              text-uppercase
            ">
            <h5 class="mb-0" v-if="!isEdit">Add New Category</h5>
            <h5 class="mb-0" v-if="isEdit">Edit Category</h5>
            <b-button variant="outline-danger" @click="close"> X </b-button>
          </div>
        </template>

        <form ref="form" @submit.prevent="handleSubmit">
          <b-form-group>
            <!-- Title -->
            <b-row class="mb-2">
              <b-col>
                <div>
                  <label for="title" class="font-weight-bolder">Name :</label>
                  <b-form-input v-model="postForm.name" id="title" placeholder="Enter Category Title" required>
                  </b-form-input>
                  <span class=" text-danger" v-if="errors.name" >{{ errors.name[0] }}</span>
                </div>
              </b-col>
            </b-row>

            <!-- status & parent_id -->
            <b-row class="mb-2">
              <b-col>
                <div>
                  <label for="status" class="font-weight-bolder">Status :</label>
                  <b-form-checkbox v-model="checked" @change="toggle(checked)" name="check-button" switch>
                  </b-form-checkbox>
                  <span class=" text-danger" v-if="errors.status" >{{ errors.status[0] }}</span>
                </div>
              </b-col>

              <b-col>
                <div>
                  <label for="title" class="font-weight-bolder">Parent Id :</label>
                  <b-form-select v-model="postForm.parent_id">
                    <b-form-select-option disabled value="">Please Select Parent Category</b-form-select-option>
                    <b-form-select-option v-for="c in categories" :key="c.id" :value="c.id">{{ c.name
                    }}</b-form-select-option>
                  </b-form-select>
                  <span class=" text-danger" v-if="errors.parent_id" >{{ errors.parent_id[0] }}</span>
                </div>
              </b-col>
            </b-row>

            <!-- cagtegory_type -->
            <div class="mb-2">
              <div>
                <label for="title" class="font-weight-bolder">Category Type</label>
                <b-form-select v-model="postForm.category_type">
                  <b-form-select-option disabled value="">Please Select Category Type</b-form-select-option>
                  <b-form-select-option v-for="(c_t, index) in category_types" :key="index" :value="c_t">{{ c_t
                  }}</b-form-select-option>
                </b-form-select>
                <span class=" text-danger" v-if="errors.category_type" >{{ errors.category_type[0] }}</span>
              </div>
            </div>
            <!-- Photo -->
            <div>
              <MyPhotoSingleUpload @getPhotoId="uploadPhoto" :isEdit="isEdit" :editPhotoObj="photoObj" />
              <span class=" text-danger" v-if="errors.photo" >{{ errors.photo[0] }}</span>
            </div>
          </b-form-group>

          <template v-if="!isEdit">
            {{ allUploaded() }}
          </template>

          <div class="w-100 text-center" v-if="!isEdit">
            <b-button variant="primary" type="submit" class="col-6">
              <span>Submit</span>
            </b-button>
          </div>

          <div class="w-100 text-center" v-if="isEdit">
            <b-button variant="primary" @click="updateCategoryData(postForm.id)" class="col-6" type="button">
              <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
              <span v-else>Update</span>
            </b-button>
          </div>
        </form>
      </b-modal>
    </div>
    <!--Model-->
  </div>
</template>

<script>
import store from "@/store";
import Table from "../components/Table.vue";
import MyPhotoSingleUpload from "../components/MyPhotoSingleUpload.vue";
import { ref, reactive } from "@vue/composition-api";
import {
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BModal,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormCheckbox,
    Table,
    MyPhotoSingleUpload,
  },
  emits: ["input"],
  setup(props, { emit }) {
    const fields = [
      { key: "id", label: "#" },
      { key: "name", label: "title" },
      { key: "category_type", label: "category type" },
      { key: "photo_url", label: "photo" },
      { key: "actions", label: "actions" },
    ];
    const spinner = ref(false);
    const myModal = ref(false);
    const isEdit = ref(false);
    const categories = ref([]);
    const category_types = ref(["service", "event", "blog", "notification"]);
    const checked = ref(true);
    const photoObj = ref({});
    const isAllUploaded = ref(false);
    const postForm = ref({
      id: "",
      name: "",
      photo: "",
      status: "active",
      parent_id: 0,
      category_type: "",
    });
    const errors = ref({});

    //Datas Fetching
    const fetchCategories = () => {
      spinner.value = true;
      store
        .dispatch("app-mypages/fetchDatas", "categories")
        .then((response) => {
          categories.value = response.data.data;
          spinner.value = false;
        }).catch((err) => console.log(err));
    };
    fetchCategories();

    //Functions
    // Photo Upload
    const uploadPhoto = (photoId) => {
      console.log(photoId)
      postForm.value.photo = photoId;
      console.log('form photo' , postForm.value.photo)
    };

    const toggle = (c) => {
      c ? (postForm.value.status = "active") : (postForm.value.status = "inactive");
      console.log(postForm.value.status);
    };

    //Data Posting
    const handleSubmit = () => {
      console.log(postForm.value)
      spinner.value = true;
      store
        .dispatch("app-mypages/postDatas", {
          path: "categories",
          payload: postForm.value,
        })
        .then((response) => {
          spinner.value = false;
          postForm.name = "";
          postForm.photo = "";
          postForm.status = 1;
          postForm.category_type = 0;
          postForm.parent_id = "";
          fetchCategories();
          myModal.value = false;
          isEdit.value = false;
        }).catch((err) => {
          spinner.value = false;
          errors.value = err.response?.data?.errors
        })
    };

    // Data Editing
    const editCategoryData = (item) => {
      myModal.value = true;
      isEdit.value = true;

      postForm.value.id = item.id;
      postForm.value.name = item.name;
      postForm.value.photo = item.photo.id;
      photoObj.value = item.photo;
      postForm.value.status = item.status ? 'active' : 'inactive';
      postForm.value.parent_id = item.parent_id;
      postForm.value.category_type = item.category_type;
    };
    const updateCategoryData = (id) => {
      spinner.value = true;
      store
        .dispatch("app-mypages/updateData", {
          path: "categories",
          payload: postForm.value,
        })
        .then((response) => {
          fetchCategories();
          spinner.value = false;
          myModal.value = false;
          isEdit.value = false;
        }).catch((err) => {
          spinner.value = false;
          errors.value = err.response?.data?.errors
        })
    };

    // Data Deleting
    const delCategoryData = (id) => {
      spinner.value = true;
      console.log(id);
      store.dispatch("app-mypages/delData", { path: "categories", id }).then((response) => {
        fetchCategories();
        spinner.value = false;
      }).catch(() => {
          spinner.value = false;
        });;
    };

    // Close Function
    const close = () => {
      myModal.value = false;
      isEdit.value = false;

      postForm.value.id = "";
      postForm.value.name = "";
      postForm.value.parent_id = 0;
      postForm.value.photo = "";
      postForm.value.status = 1;
      postForm.value.category_type = "";
      photoObj.value = {};
    };

    // All Uploaded
    const allUploaded = () => {
      if (postForm.value.name &&
        postForm.value.category_type &&
        postForm.value.photo.length !== 0) {
        isAllUploaded.value = true;
      }
    }

    return {
      fields,
      spinner,
      myModal,
      isEdit,
      categories,
      category_types,
      handleSubmit,
      photoObj,
      uploadPhoto,
      toggle,
      postForm,
      checked,
      editCategoryData,
      updateCategoryData,
      delCategoryData,
      close,
      allUploaded,
      isAllUploaded,
      errors
    };
  },
};
</script>