<template>
  <div>
    <b-card no-body class="mb-0">
      <!-- Loading -->
      <template v-if="spinner === true">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <div class="text-center m-5">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
          </div>
        </div>
      </template>

      <b-card v-else no-body class="mb-0 py-3 px-5 card-company-table">
        <div v-for="e in eventDetails" :key="e.id" class="mb-5">
          <!-- id & actions -->
          <b-row class="mb-3">
            <b-col md="6">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                #
              </h4>
              <h4 class="font-weight-normal">{{ e.id }}</h4>
            </b-col>
            <b-col md="6">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                Update ({{ e.title }})
              </h4>
              <div>
                <!-- edit btn -->
                <router-link  :to="{name : 'EditEvent' , params : {id : e.id}}" class="dropdown-item-con">
                  <b-button pill variant="primary" class="mr-1">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Update</span>
                  </b-button>
                </router-link>

                <!-- delete btn -->
                <!-- <b-button
                  pill
                  size="sm"
                  variant="danger"
                  @click="
                    () => {
                      isEdit = false;
                    }
                  "
                  v-b-modal.modal-scrollable-actions
                >
                  <span class="text-nowrap">Delete</span>
                </b-button> -->
              </div>
            </b-col>
          </b-row>
          <!-- title & sub_title -->
          <b-row class="mb-3">
            <b-col md="6">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                title :
              </h4>
              <h4 class="font-weight-normal">{{ e.title }}</h4>
            </b-col>
            <b-col md="6">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                sub title :
              </h4>
              <h4 class="font-weight-normal">{{ e.sub_title }}</h4>
            </b-col>
          </b-row>
          <!-- description, contact_info -->
          <b-row class="mb-3">
            <b-col md="12">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                description :
              </h4>
              <!-- <h4 class="font-weight-normal" v-html="e.description"></h4> -->

              <b-button pill size="sm" variant="primary" v-b-modal.modal-scrollable-des>
                <span class="text-nowrap">Details</span>
              </b-button>
            </b-col>
          </b-row>
          <!-- contact_info -->
          <b-row class="mb-3">
            <b-col md="12">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                contact info :
              </h4>
              <h4 class="font-weight-normal">{{ e.contact_info }}</h4>
            </b-col>
          </b-row>
          <!-- email & ph -->
          <b-row class="mb-3">
            <b-col md="6">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                email :
              </h4>
              <h4 class="font-weight-normal">{{ e.email }}</h4>
            </b-col>
            <b-col md="6">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                phone :
              </h4>
              <h4 class="font-weight-normal">{{ e.phone }}</h4>
            </b-col>
          </b-row>
          <!-- email & ph -->
          <b-row class="mb-3">
            <b-col md="6">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                Event Start Date :
              </h4>
              <h4 class="font-weight-normal">{{ e.opening_date }}</h4>
            </b-col>
            <b-col md="6">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                Event End Date :
              </h4>
              <h4 class="font-weight-normal">{{ e.closing_date }}</h4>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col md="12">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                ticket mode :
              </h4>
              <h4 class="font-weight-normal">
                {{ e.ticket_mode === 1 ? "true" : null }}
              </h4>
              <h4 class="font-weight-normal">
                {{ e.ticket_mode === 0 ? "false" : null }}
              </h4>
            </b-col>
          </b-row>

          <!-- tickets & photos -->
          <b-row>
            <!-- tickets -->
            <b-col md="6">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                Tickets :
              </h4>
              <b-button pill size="sm" variant="primary" @click="() => {
                isTicket = true;
                isPhoto = false;
              }
                " v-b-modal.modal-scrollable class="d-block">
                <span class="text-nowrap">Details</span>
              </b-button>
            </b-col>
            <!-- photos -->
            <b-col md="6">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                photos :
              </h4>
              <b-button pill size="sm" variant="primary" @click="() => {
                isTicket = false;
                isPhoto = true;
              }
                " v-b-modal.modal-scrollable class="d-block">
                <span class="text-nowrap">Details</span>
              </b-button>
            </b-col>
          </b-row>
        </div>

        <div class="w-100 text-center">
          <b-button type="button" variant="primary" @click="() => router.go(-1)" class="col-md-10 col-lg-6">
            <!-- <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner> -->
            <span>Back</span>
          </b-button>
        </div>
      </b-card>
    </b-card>

    <!--Model-->
    <div>
      <b-modal v-model="myModal" id="modal-scrollable" class="modal-scrollable" size="lg" header-class="text-uppercase"
        scrollable hide-footer>
        <template #modal-header>
          <!-- Emulate built in modal header close button action -->
          <div class="w-100 d-flex justify-content-between align-items-center">
            <h5 class="mb-0" v-if="isTicket">Tickets</h5>
            <h5 class="mb-0" v-if="isPhoto">Photos</h5>
            <b-button variant="outline-danger" @click="close"> X </b-button>
          </div>
        </template>
        <b-tabs fill lazy v-if="isTicket">
          <!-- Event Pending Tickets Table -->
          <Table :items="tickets" :fields="ticketsFields" @editFunc="pendingTicketData" />
        </b-tabs>

        <template v-if="isPhoto">
          <MyLightBox :items="eventDetails[0]" @getUpdatePhotoId="uploadUpdatePhoto" @updatePhotoFromChild="updatePhoto"
            :postFormEditDatas="editOriVals" :fetchDetails="fetchEventDetails" :postForm="postForm" :myModal="myModal"
            urlName="events" />
        </template>
      </b-modal>
    </div>
    <!--Model-->

    <!-- Tickets Approve or Decline Modal-->
    <div>
      <b-modal v-model="pendingModal" id="modal-center-pending" centered header-class="text-uppercase justify-content-end"
        hide-footer>
        <template #modal-header>
          <!-- Emulate built in modal header close button action -->
          <div class="">
            <!-- <h5 class="mb-0">Tickets</h5> -->
            <b-button variant="outline-danger" @click="pendingModal = false" class="float-right">
              X
            </b-button>
          </div>
        </template>
        <!-- Event Pending -->
        <h4 class="text-center mb-2" v-if="ticketPostData.ticket_status_id === 1">
          Do you want to Approve or Decline?
        </h4>
        <h4 class="text-center mb-2" v-else>
          Do you want to
          {{ ticketPostData.ticket_status_id === 2 ? "Decline" : "Approve" }}?
        </h4>
        <div class="d-flex justify-content-around align-items-center">
          <b-button v-if="ticketPostData.ticket_status_id !== 2" pill size="lg" variant="success"
            @click="updateTicketData(2)">
            <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
            <span v-else>Approve</span>
          </b-button>
          <b-button v-if="ticketPostData.ticket_status_id !== 3" pill size="lg" variant="danger"
            @click="updateTicketData(3)">
            <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
            <span v-else>Decline</span>
          </b-button>
        </div>
      </b-modal>
    </div>
    <!--Model-->

    <DescriptionModal :desData="eventDetails[0]" />
  </div>
</template>
  
<script>
import store from "@/store";
import { useRouter } from "@core/utils/utils";
import { ref, reactive, computed, watch } from "@vue/composition-api";
import Table from "../components/Table.vue";
import MyLightBox from "../components/MyLightBox.vue";
import DescriptionModal from "../components/DescriptionModal.vue";
import { VueEditor } from "vue2-quill-editor";
import VueUploadMultipleImage from "vue-upload-multiple-image";

import {
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BModal,
  BFormTextarea,
  BTabs,
  BTab,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
} from "bootstrap-vue";
import router from "@/router";

export default {
  components: {
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
    BTabs,
    BTab,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    Table,
    MyLightBox,
    DescriptionModal,
    VueEditor,
    VueUploadMultipleImage,
  },
  props: ["query"],
  setup(props) {
    const { router } = useRouter();
    const ticketsFields = [
      { key: "id", label: "#" },
      { key: "user_id", label: "user id" },
      { key: "ticket_status_id", label: "ticket status" },
      { key: "remark", label: "remark" },
    ];
    const spinner = ref(false);
    const myModal = ref(false);
    const editDelModal = ref(false);
    const isTicket = ref(false);
    const isPhoto = ref(false);
    const isEdit = ref(false);
    const pendingModal = ref(false);
    const checked = ref(true);
    const tickets = ref([]);
    const eventDetails = ref([]);
    const categories = ref([]);
    const updatePhotoArr = ref([]);
    const eventId = ref(props.query.id);
    const ticketPostData = ref({});
    const postForm = reactive({
      id: "",
      title: "",
      category_id: 0,
      sub_title: "",
      description: "",
      contact_info: "",
      email: "",
      phone: "",
      lat: "",
      lng: "",
      ticket_mode: 1,
      opening_date: "",
      closing_date: "",
      photos: [],
    });

    // Functions
    const clearDatas = () => {
      postForm.title = "";
      postForm.category_id = 0;
      postForm.sub_title = "";
      postForm.description = "";
      postForm.contact_info = "";
      postForm.phone = "";
      postForm.email = "";
      postForm.lat = "";
      postForm.lng = "";
      postForm.ticket_mode = 1;
      postForm.opening_date = "";
      postForm.closing_date = "";
    };
    // Edit Function
    const editOriVals = () => {
      postForm.id = eventDetails.value[0].id;
      postForm.title = eventDetails.value[0].title;
      postForm.category_id = eventDetails.value[0].category_id;
      postForm.sub_title = eventDetails.value[0].sub_title;
      postForm.description = eventDetails.value[0].description;
      postForm.contact_info = eventDetails.value[0].contact_info;
      postForm.phone = eventDetails.value[0].phone;
      postForm.email = eventDetails.value[0].email;
      postForm.lat = eventDetails.value[0].lat;
      postForm.lng = eventDetails.value[0].lng;
      postForm.ticket_mode = eventDetails.value[0].ticket_mode;
      postForm.opening_date = eventDetails.value[0].opening_date;
      postForm.closing_date = eventDetails.value[0].closing_date;
    };

    //Datas Fetching
    const fetchEventDetails = () => {
      spinner.value = true;
      store
        .dispatch("app-mypages/fetchDetails", {
          path: "events",
          id: eventId.value,
        })
        .then((response) => {
          eventDetails.value = [response.data.data];
          spinner.value = false;
        });
    };
    fetchEventDetails();
    const fetchTickets = () => {
      store.dispatch("app-mypages/fetchDatas", "tickets").then((response) => {
        tickets.value = response.data.data;
      });
    };
    fetchTickets();
    const fetchCategories = () => {
      // store.dispatch("app-categories/fetchCategories").then((response) => {
      //   categories.value = response.data.data;
      // });
    };
    fetchCategories();

    // Update Photos
    const uploadUpdatePhoto = (updatePhotoIds) => {
      updatePhotoArr.value = updatePhotoIds;
      postForm.photos = updatePhotoArr.value;
    };
    const updatePhoto = () => {
      spinner.value = true;
      editOriVals();
      store
        .dispatch("app-mypages/updatePhotoArr", {
          path: `events/${eventDetails.value[0].id}`,
          payload: postForm,
        })
        .then((response) => {
          fetchEventDetails();
          spinner.value = false;
          myModal.value = false;
          editDelModal.value = false;
          isEdit.value = false;
          clearDatas();
        });
    };

    const pendingTicketData = (postTicketDatas) => {
      ticketPostData.value = postTicketDatas;
    };
    const updateTicketData = (status_id) => {
      spinner.value = true;
      pendingModal.value = false;
      ticketPostData.value.ticket_status_id = status_id;
      store
        .dispatch(`app-mypages/updateData`, {
          path: "tickets",
          payload: ticketPostData.value,
        })
        .then((response) => {
          fetchTickets();
          spinner.value = false;
          isEdit.value = false;
        });
    };

    // Data Editing
    const editEventData = () => {
      editDelModal.value = true;
      isEdit.value = true;
      editOriVals();
    };
    0;
    const updateEventData = () => {
      spinner.value = true;
      store
        .dispatch("app-mypages/updateData", {
          path: "events",
          payload: postForm,
        })
        .then((response) => {
          fetchEventDetails();
          spinner.value = false;
          editDelModal.value = false;
          isEdit.value = false;
          clearDatas();
        });
    };

    // Data Deleting
    const delEventData = () => {
      spinner.value = true;
      editDelModal.value = false;
      store
        .dispatch("app-mypages/delData", {
          path: "events",
          id: eventDetails.value[0].id,
        })
        .then((response) => {
          spinner.value = false;
          router.go(-1);
        });
    };

    //Functions
    const toggle = (c) => {
      c ? (postForm.ticket_mode = 1) : (postForm.ticket_mode = 0);
      console.log(postForm.ticket_mode);
    };

    const close = () => {
      editDelModal.value = false;
      pendingModal.value = false;
      myModal.value = false;
      // isEdit.value = false;
      isTicket.value = false;
      isPhoto.value = false;
      clearDatas();
    };

    return {
      spinner,
      myModal,
      editDelModal,
      pendingModal,
      fetchEventDetails,
      postForm,
      editOriVals,
      isEdit,
      isTicket,
      isPhoto,
      uploadUpdatePhoto,
      updatePhoto,
      eventDetails,
      ticketsFields,
      tickets,
      ticketPostData,
      pendingTicketData,
      updateTicketData,
      toggle,
      checked,
      postForm,
      categories,
      editEventData,
      updateEventData,
      delEventData,
      close,
      router,
    };
  },
};
</script>
  
<style scoped>
.text-underline {
  text-decoration: underline;
}
</style>
  