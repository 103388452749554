<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div>
    <b-card no-body class="mb-0">
      <!-- Loading -->
      <template v-if="spinner === true">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <div class="text-center m-5">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
          </div>
        </div>
      </template>

      <b-card v-else no-body class="mb-0 card-company-table">
        <!-- Search -->
        <div class="m-2">
          <b-row>
            <b-col md="6">
              <FSearchInput urlName="blog" :fetchDatas="fetchNewsDatas" :isNews="true" />
            </b-col>
            <b-col md="6">
              <FCategoriesCom urlName="blog" @getNewsCategories="getCategories" :isNews="true" />
            </b-col>

            <b-col cols="12" md="12">
              <div class="d-flex align-items-center justify-content-end">
                <!-- <router-link
                  :to="{
                    name: 'add-new',
                    query: {
                      categories: JSON.stringify(categories),
                      tags: JSON.stringify(tags),
                      isService: false,
                      isEvent: false,
                      isNews: true,
                      isEdit: false,
                    },
                  }"
                >
                  <b-button variant="primary">
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="text-nowrap">Add New Blog</span>
                  </b-button>
                </router-link> -->
                <router-link :to="{name: 'CreateBlog'}">
                  <b-button variant="primary">
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="text-nowrap">Add New Blog</span>
                  </b-button>
                </router-link>
              </div>
            </b-col>
          </b-row>
        </div>

        <vue-good-table
          :columns="fields"
          :rows="newsDatas"
          :theme="skin == 'dark' ? 'nocturnal' : ''"
          :pagination-options="{enabled : true}"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item  :to="{ name: 'EditBlog', params: { id: props.row.id }, }">
                    <router-link :to="{ name: 'EditBlog', params: { id: props.row.id }, }" class="dropdown-item-con">
                      <feather-icon icon="BookOpenIcon" />
                      <span class="align-middle ml-50">Details</span>
                    </router-link>
                  </b-dropdown-item>
                  <b-dropdown-item :to="{name : 'EditBlog' , params : {id : props.row.id}}">
                    <feather-icon icon="EditIcon" />
                    <router-link :to="{name : 'EditBlog' , params : {id : props.row.id}}" class="align-middle ml-50">Edit</router-link>
                  </b-dropdown-item>
                  <b-dropdown-item @click="delBlogData(props.row.id)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
            </span>

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div style="margin: 1rem 0rem;display: flex;justify-content: end;">
              <b-pagination
                v-if="meta.current_page"
                v-model="meta.current_page"
                :total-rows="meta.total"
                :per-page="meta.per_page"
                @change="changePage"
              ></b-pagination>
            </div>
          </template>
        </vue-good-table>


        <!-- News Table -->
        <!-- <Table :items="newsDatas" :fields="fields" :isNews="true" @photosDetails="savePhotos" @desDetail="desDetailsData"
        @delFunc="delBlogData" :categories="categories" :tags="tags" :fetchDatas="fetchNewsDatas" pageName="news" :pageNum="pageNum" /> -->
      </b-card>
    </b-card>

    <!-- Description Modal-->
    <DescriptionModal :desData="desData" />
    <!--Description Modal-->

    <!-- Photos Modal-->
    <div>
      <b-modal v-model="photosModal" id="modal-scrollable-photos" class="modal-scrollable" size="lg"
        header-class="text-uppercase" scrollable hide-footer>
        <template #modal-header>
          <!-- Emulate built in modal header close button action -->
          <div class="
              w-100
              d-flex
              justify-content-between
              align-items-center
              text-uppercase
            ">
            <h5 class="mb-0">Tickets</h5>
            <b-button variant="outline-danger" @click="photosModal = false"> X </b-button>
          </div>
        </template>

        <MyLightBox :items="newsDetails" @getUpdatePhotoId="uploadUpdatePhoto" @updatePhotoFromChild="updatePhoto"
          :postFormEditDatas="editOriVals" :fetchDetails="fetchNewsDatas" :postForm="postForm" :myModal="myModal"
          urlName="news" @delFunc="delBlogData" />
      </b-modal>
    </div>
    <!--Photos Modal-->
  </div>
</template>
  
<script>
import store from "@/store";
import Table from "../components/Table.vue";
import MyLightBox from "../components/MyLightBox.vue";
// import MapLocationSelector from 'vue-latlng-picker';
import MyPhotoUpload from "../components/MyPhotoUpload.vue";
import DescriptionModal from "../components/DescriptionModal.vue";
import FCategoriesCom from "../components/FCategoriesCom.vue";
import FSearchInput from "../components/FSearchInput.vue";
import { VueEditor } from "vue2-quill-editor";
import { useRouter } from "@core/utils/utils";
import { ref, reactive, computed, watch , onMounted } from "@vue/composition-api";
import {
  BPagination,
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BModal,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormCheckbox,
    // MapLocationSelector,
    MyPhotoUpload,
    DescriptionModal,
    VueEditor,
    Table,
    MyLightBox,
    FCategoriesCom,
    FSearchInput,
  },
  setup() {
    const skin = ref(localStorage.getItem('vuexy-skin'));
    onMounted(() => {
        window.addEventListener('vuexy-skin-localstorage-changed', (event) => {
          skin.value = event.detail.storage;
        });
    });
    const { route, router } = useRouter();
    // const fields = [
    //   { key: "id", label: "#" },
    //   { key: "title", label: "title" },
    //   { key: "sub_title", label: "sub title" },
    //   { key: "category.name", label: "category" },
    //   { key: "tags", label: "tags" },
    //   { key: "description", label: "description" },
    //   { key: "images", label: "photos" },
    //   { key: "actions", label: "actions" },
    // ];
    const fields = [
      { sortable : false, field: "id", label: "#" },
      { sortable : false, field: "title", label: "title" },
      { sortable : false, field: "sub_title", label: "sub title" },
      { sortable : false, field: "category.name", label: "category" },
      { sortable : false, field: "actions", label: "actions" },
    ];

    const spinner = ref(false);
    const myModal = ref(false);
    const photosModal = ref(false);
    const pageNum = ref(1);
    const desData = ref({});
    const newsDatas = ref([]);
    const categories = ref([]);
    const tags = ref([]);
    const newsDetails = ref({});
    const updatePhotoArr = ref([]);
    const postForm = reactive({
      id: "",
      title: "",
      category_id: "",
      sub_title: "",
      description: "",
      author: "",
      status: "publish",
      tags: [],
      photos: [],
    });
    const meta = ref({});

    // Functions
    const clearDatas = () => {
      postForm.title = "";
      postForm.category_id = "";
      postForm.sub_title = "";
      postForm.description = "";
      postForm.author = "";
      postForm.status = "publish";
    };
    // Edit Function
    const editOriVals = () => {
      postForm.id = newsDetails.value.id;
      postForm.title = newsDetails.value.title;
      postForm.category_id = newsDetails.value.category_id;
      postForm.sub_title = newsDetails.value.sub_title;
      postForm.description = newsDetails.value.description;
      postForm.author = newsDetails.value.author;
      postForm.status = newsDetails.value.status;
    };

    //Datas Fetching
    const fetchNewsDatas = () => {
      spinner.value = true;
      if (router.app._route.query.page) {
        pageNum.value = router.app._route.query.page;
      }

      if (router.app._route.query.category_id) {
        pageNum.value = router.app._route.query.category_id;
        console.log(pageNum.value);
      }

      // if (router.app._route.query.search) {
      //   pageNum.value = router.app._route.query.search;
      // }

      let queryKey = Object.keys(router.app._route.query)[0] || "page";
      if (router.app._route.query.search) {
        store
          .dispatch("app-mypages/fetchDatas", `news?${queryKey}=${pageNum.value}&search=${router.app._route.query.search}`)
          .then((response) => {
            newsDatas.value = response.data.data;
            meta.value = response.data.meta;
            spinner.value = false;
          });
      } else {
        store
          .dispatch("app-mypages/fetchDatas", `news?${queryKey}=${pageNum.value}`)
          .then((response) => {
            newsDatas.value = response.data.data;
            meta.value = response.data.meta;
            spinner.value = false;
          });
      }
    };
    fetchNewsDatas();
    const changePage = (e) => {
      router.push({name : 'apps-blog' , query : { ...router.app._route.query , page : e}})
      pageNum.value = e;
      fetchNewsDatas();
    }
    const fetchTags = () => {
      spinner.value = true;
      store
        .dispatch("app-mypages/fetchDatas", "tags")
        .then((response) => {
          tags.value = response.data.data;
          spinner.value = false;
        });
    };
    fetchTags();
    // Route Params
    const routeParams = computed(() => route.value.params);
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      fetchNewsDatas();
    });
    const getCategories = (fetchedCategories) => {
      categories.value = fetchedCategories;
    };

    // Update Photos
    const uploadUpdatePhoto = (updatePhotoIds) => {
      updatePhotoArr.value = updatePhotoIds;
      postForm.photos = updatePhotoArr.value;
    };
    const updatePhoto = () => {
      spinner.value = true;
      editOriVals();
      store
        .dispatch("app-mypages/updatePhotoArr", {
          path: `news/${newsDetails.value.id}`,
          payload: postForm,
        })
        .then((response) => {
          fetchNewsDatas();
          spinner.value = false;
          myModal.value = false;
          photosModal.value = false;
          clearDatas();
        });
    };
    //Functions
    const savePhotos = (newsDatas) => {
      newsDetails.value = newsDatas;
    };
    const desDetailsData = (data) => {
      desData.value = data;
    };

    // Data Deleting
    const delBlogData = (id) => {
      spinner.value = true;
      store
        .dispatch("app-mypages/delData", { path: "news", id })
        .then((response) => {
          fetchNewsDatas();
          spinner.value = false;
        }).catch(() => {
          spinner.value = false;
        });;
    };

    return {
      fields,
      spinner,
      pageNum,
      myModal,
      desData,
      desDetailsData,
      photosModal,
      fetchNewsDatas,
      postForm,
      newsDatas,
      categories,
      tags,
      getCategories,
      newsDetails,
      savePhotos,
      uploadUpdatePhoto,
      updatePhoto,
      editOriVals,
      delBlogData,
      meta,
      changePage,
      skin
    };
  },
};
</script>